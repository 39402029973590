
export default function ConfigManager() {

    let instance;
    

    function init() {

        

        if (process.env.REACT_APP_ENV === 'DEBUG' || process.env.NODE_ENV === 'development') {
            
        }
        let g2ReviewLink = "https://www.g2.com/products/authn-by-idee/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fauthn-by-idee%2Ftake_survey";

        return {
            g2ReviewLink: g2ReviewLink
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }
            return instance;
        }
    };

};